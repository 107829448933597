<template>
  <div>
    <v-card class="pa-2 " style="background-color: #f2f4f8">
      <v-card-title>
        <v-layout align-center>
          <v-btn
            style="
              background-color: rgb(41, 47, 125);
              border-color: rgb(41, 47, 125);
            "
            icon
            medium
            @click="addModal"
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
          <span
            class="title"
            style="
              color: #292f7d;
              font-family: 'Open Sans', 'Arial', monospace!important;
            "
            >{{ $t('Add Report Recipient') }}</span
          >
        </v-layout>
      </v-card-title>
    </v-card>
    <!-- <v-layout row style="margin: 30px">
      <v-flex class="left" style="margin-left: 16px">
        <span
        class="mt-2"
          style="
            color: rgb(41, 47, 125);
            font-size: 20px !important;
            font-weight: 500;
            line-height: 1 !important;
            letter-spacing: 0.02em !important;
            font-family: 'Open Sans', sans-serif !important;
          "
          >{{ $t('Add Report Recipient') }}</span
        >
      </v-flex>
    </v-layout> -->
    <v-layout row wrap center class="pa-3 ma-4">
      <v-card
        v-if="tableData"
        class="borderedCard"
        style="width:100%"
      >
        <div class="center font-weight-bold mt-2 font-20">
          {{ $t('Periodic reports for project') }}
          {{ $t($store.state.User.project.name) }}
        </div>
        <v-data-table
          :headers="headers"
          :items="tableData"
          class="elevation-1 pl-4 pr-4 pb-4"
          hide-actions
          :no-data-text="$t('No data available')"
        >
          <template slot="headerCell" slot-scope="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="lang === 'en' ? 'left' : 'right'">
                  {{ $t(props.header.text) }}
                </span>
              </template>
              <span>
                {{ $t(props.header.text) }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:items="props">
            <tr>
              <td class="text-xs">
                {{ getReportsName(props.item.Reports) }}
              </td>
              <td class="text-xs">
                {{ $t(props.item.Name) }}
              </td>
              <td class="text-xs">
                {{ $t(props.item.Email) }}
              </td>
              <td class="text-xs">
                {{ $t(props.item.Period) }}
              </td>
              <td class="text-xs">
                {{ $t(props.item.When) }}
              </td>
              <td class="text-xs">
                <v-icon style="color: green" v-if="props.item.Active"
                  >mdi-check</v-icon
                >
                <v-icon style="color: red" v-else>mdi-close</v-icon>
              </td>
              <td class="text-xs">
                <v-icon @click="editModal(props.index)" class="clickable"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  @click="deleteModal(props.index)"
                  style="color: #e57373"
                  class="mx-4 clickable"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-card v-else>
        <v-flex xs12 style="color: red" class="center mt-0 font-20">{{
          $t('There are no report recipient for this project')
        }}</v-flex>
      </v-card>
    </v-layout>
    <v-layout row wrap center>
      <v-btn
        class="mb-2 saveDiv ml-3 mr-3 accent-4"
        color="rgb(129, 46, 129)"
        @click="saveChanges"
      >
        {{ $t('Save Changes') }}
      </v-btn>
    </v-layout>
    <transition name="modal" v-if="modalOpen">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div v-if="modalType === 'add'">
              <div style="background-color: purple; height: 40px">
                <span class="pt-2" style="position: relative"
                  ><h2
                    style="color: white"
                    class="center font-20 font-bold pt-1 mt-0"
                  >
                    {{ $t('Add Report Recipient') }}
                  </h2>
                  <v-icon class="closeIcon" @click="closeModal()" color="white"
                    >mdi-close</v-icon
                  ></span
                >
              </div>
              <div class="mt-2 mb-2">
                <v-layout row wrap>
                  <v-flex xs3 class="mb-3 mt-2">
                    <label class="right" for="name"
                      ><span class="userLabels">{{ $t('Name') }} :</span></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3 mt-2">
                    <input
                      class="userInputs"
                      type="text"
                      name="name"
                      id="name"
                      :value="item.Name"
                      @input="(event) => (item.Name = event.target.value)"
                    />
                  </v-flex>
                  <v-flex xs3 class="mb-3 mt-1">
                    <label class="right" for="email"
                      ><span class="userLabels"
                        >{{ $t('Email') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3 mt-1">
                    <input
                      class="userInputs"
                      type="text"
                      name="email"
                      id="email"
                      :value="item.Email"
                      @input="(event) => (item.Email = event.target.value)"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    style="color: red"
                    class="center mt-0"
                    v-if="invalidEmail"
                    >{{ $t(invalidEmail) }}</v-flex
                  >
                  <v-flex xs3 class="mb-3 mt-1">
                    <label class="right" for="reports"
                      ><span class="userLabels"
                        >{{ $t('Reports') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3 mt-1">
                    <v-select
                      v-if="lang === 'en'"
                      v-model="reportsPicked"
                      item-text="label"
                      item-value="value"
                      :items="reports"
                      multiple
                      :placeholder="this.$t(`Select Reports`)"
                      open-direction="bottom"
                      style="width: 300px"
                    />
                    <v-select
                      v-else
                      v-model="reportsPicked"
                      :items="reportsTranslation"
                      item-text="label"
                      item-value="value"
                      multiple
                      :placeholder="this.$t(`Select Reports`)"
                      open-direction="bottom"
                      style="width: 300px"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    style="color: red"
                    class="center mt-0"
                    v-if="invalidReports"
                    >{{ $t(invalidReports) }}</v-flex
                  >
                  <v-flex xs3 class="mb-3">
                    <label class="right" for="period"
                      ><span class="userLabels"
                        >{{ $t('Period') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3">
                    <v-select
                      v-model="item.Period"
                      item-text="label"
                      item-value="value"
                      :items="periodOptions"
                      :placeholder="this.$t(`Select Period`)"
                      open-direction="bottom"
                      style="width: 300px"
                    />
                  </v-flex>
                  <v-flex xs3 class="mb-3 mt-2" v-if="item.Period !== 'daily'">
                    <label class="right" for="when"
                      ><span class="userLabels">{{ $t('When') }} :</span></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3" v-if="item.Period !== 'daily'">
                    <v-select
                      v-model="item.When"
                      item-text="label"
                      item-value="value"
                      :items="timeOptions"
                      :placeholder="this.$t(`Select Day`)"
                      open-direction="bottom"
                      style="width: 300px"
                    />
                  </v-flex>
                  <v-flex xs3 class="mb-3 mt-2">
                    <label class="right" for="active"
                      ><span class="userLabels"
                        >{{ $t('Active') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs9 class="mb-3 mt-2">
                    <input
                      class="userInputs"
                      type="checkbox"
                      name="active"
                      id="active"
                      :value="item.Active"
                      @change="() => (item.Active = !item.Active)"
                    />
                  </v-flex>
                  <v-flex xs12 class="center">
                    <v-btn
                      color="rgb(129, 46, 129)"
                      class="mb-2 addRowDiv ml-3 mr-3"
                      @click="addRow(index)"
                    >
                      {{ $t('Add') }}
                    </v-btn>
                    <v-btn
                      color="rgb(41, 47, 125)"
                      class="mb-2 cancelDiv ml-3 mr-3"
                      @click="closeModal()"
                      >{{ $t('Cancel') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </div>
            <div v-if="modalType === 'edit'">
              <div style="background-color: purple; height: 40px">
                <span class="pt-2" style="position: relative"
                  ><h2
                    style="color: white"
                    class="center font-20 font-bold pt-1 mt-0"
                  >
                    {{ $t('Edit Report Recipient') }}
                  </h2>
                  <v-icon class="closeIcon" @click="closeModal()" color="white"
                    >mdi-close</v-icon
                  ></span
                >
              </div>
              <v-layout row wrap>
                <v-flex xs3 class="mb-3 mt-2">
                  <label class="right" for="name"
                    ><span class="userLabels">{{ $t('Name') }} :</span></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3 mt-2">
                  <input
                    class="userInputs"
                    type="text"
                    name="name"
                    id="name"
                    :value="item.Name"
                    @input="(event) => (item.Name = event.target.value)"
                  />
                </v-flex>
                <v-flex xs3 class="mb-3 mt-1">
                  <label class="right" for="email"
                    ><span class="userLabels">{{ $t('Email') }} :</span></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3 mt-1">
                  <input
                    class="userInputs"
                    type="text"
                    name="email"
                    id="email"
                    :value="item.Email"
                    @input="(event) => (item.Email = event.target.value)"
                  />
                </v-flex>
                <v-flex
                  xs12
                  style="color: red"
                  class="center mt-0"
                  v-if="invalidEmail"
                  >{{ $t(invalidEmail) }}</v-flex
                >
                <v-flex xs3 class="mb-3 mt-1">
                  <label class="right" for="reports"
                    ><span class="userLabels"
                      >{{ $t('Reports') }} :</span
                    ></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3 mt-1">
                  <v-select
                    v-if="lang === 'en'"
                    v-model="reportsPicked"
                    :items="reports"
                    item-text="label"
                    item-value="value"
                    multiple
                    :placeholder="this.$t(`Select Reports`)"
                    open-direction="bottom"
                    style="width: 300px"
                  />
                  <v-select
                    v-else
                    v-model="reportsPicked"
                    :items="reportsTranslation"
                    item-text="label"
                    item-value="value"
                    multiple
                    :placeholder="this.$t(`Select Reports`)"
                    open-direction="bottom"
                    style="width: 300px"
                  />
                </v-flex>
                <v-flex
                  xs12
                  style="color: red"
                  class="center mt-0"
                  v-if="invalidReports"
                  >{{ $t(invalidReports) }}</v-flex
                >
                <v-flex xs3 class="mb-3 mt-1">
                  <label class="right" for="period"
                    ><span class="userLabels">{{ $t('Period') }} :</span></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3 mt-1">
                  <v-select
                    v-model="item.Period"
                    item-text="label"
                    item-value="value"
                    :items="periodOptions"
                    :placeholder="this.$t(`Select Period`)"
                    open-direction="bottom"
                    style="width: 300px"
                  />
                </v-flex>
                <v-flex xs3 class="mb-3 mt-2" v-if="item.Period !== 'daily'">
                  <label class="right" for="when"
                    ><span class="userLabels">{{ $t('When') }} :</span></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3" v-if="item.Period !== 'daily'">
                  <v-select
                    v-model="item.When"
                    item-text="label"
                    item-value="value"
                    :items="timeOptions"
                    :placeholder="this.$t(`Select Day`)"
                    open-direction="bottom"
                    style="width: 300px"
                  />
                </v-flex>
                <v-flex xs3 class="mb-3 mt-2">
                  <label class="right" for="active"
                    ><span class="userLabels">{{ $t('Active') }} :</span></label
                  >
                </v-flex>
                <v-flex xs9 class="mb-3 mt-2">
                  <input
                    class="userInputs"
                    type="checkbox"
                    name="active"
                    id="active"
                    :value="item.Active"
                    @change="() => (item.Active = !item.Active)"
                  />
                </v-flex>
                <v-flex xs12 class="center">
                  <v-btn
                    color="rgb(129, 46, 129)"
                    class="clickable mb-2 editRowDiv ml-3 mr-3 font-weight-medium"
                    @click="addRow(index)"
                  >
                    {{ $t('Edit') }}
                  </v-btn>
                  <v-btn
                    color="rgb(41, 47, 125)"
                    class="clickable mb-2 cancelDiv ml-3 mr-3 font-weight-medium"
                    @click="closeModal()"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
            <div v-if="modalType === 'delete'">
              <div style="background-color: purple; height: 40px">
                <span class="pt-2" style="position: relative"
                  ><h2
                    style="color: white"
                    class="center font-20 font-bold pt-1 mt-0"
                  >
                    {{ $t('Delete Report Recipient') }}
                  </h2>
                  <v-icon class="closeIcon" color="white" @click="closeModal()"
                    >mdi-close</v-icon
                  ></span
                >
              </div>
              <v-layout row wrap>
                <v-flex xs12 style="color: black" class="center mt-3 font-20"
                  >{{
                    $t('Are you sure you want to delete this report recipient')
                  }}?</v-flex
                >
                <v-flex xs12 class="center">
                  <v-btn
                    color="rgb(129, 46, 129)"
                    class="mb-2 addRowDiv ml-3 mr-3"
                    @click="deleteRow(index)"
                  >
                    {{ $t('Delete') }}
                  </v-btn>
                  <v-btn
                    color="rgb(41, 47, 125)"
                    class="mb-2 cancelDiv ml-3 mr-3"
                    @click="closeModal()"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { setProjectProperty } from '@/modules/ApiUsers';
export default {
  name: 'PeriodicReports',
  components: { Multiselect },
  data() {
    return {
      lang: this.$store.state.Global.lang,
      tableData: null,
      headers: null,
      modalOpen: false,
      modalType: '',
      item: null,
      reports: ['Installation Monitoring Report'],
      reportsTranslation: ['דוח ניהול התקנות'],
      reportsLink: [
        'https://dashboard.tondo-iot.com/reports/monitoring_report'
      ],
      reportsPicked: [],
      periodOptions: [
        { value: 'daily', label: this.$t('daily') },
        { value: 'weekly', label: this.$t('weekly') },
        { value: 'monthly', label: this.$t('monthly') },
        { value: 'yearly', label: this.$t('yearly') }
      ],
      timeOptions: [
        { value: 'sunday', label: this.$t('sunday') },
        { value: 'monday', label: this.$t('monday') },
        { value: 'tuesday', label: this.$t('tuesday') },
        { value: 'wednesday', label: this.$t('wednesday') },
        { value: 'thursday', label: this.$t('thursday') },
        { value: 'friday', label: this.$t('friday') },
        { value: 'saturday', label: this.$t('saturday') }
      ],
      invalidEmail: null,
      invalidReports: null,
      index: null
    };
  },
  beforeMount() {
    this.headers = [
      { text: 'Reports', value: 'Reports', align: 'center' },
      { text: 'Name', value: 'Name', align: 'center' },
      { text: 'Email', value: 'Email', align: 'center' },
      { text: 'Period', value: 'Period', align: 'center' },
      { text: 'When Published', value: 'When', align: 'center' },
      { text: 'Active', value: 'Active', align: 'center' },
      { text: 'Action', value: 'Action', align: 'center' }
    ];
  },
  mounted() {
    this.$store.commit('Global/setPageTitle', 'Periodic Reports');
    const reportsContacts = this.$store.state.Props.list.find(
      (prop) => prop.name === 'meta.reports_contacts'
    );
    if (reportsContacts) {
      this.tableData = reportsContacts.value;
    }
  },
  methods: {
    getReportsName(reports) {
      let reportsName = '';
      for (let report = 0; report < reports.length; report++) {
        if (report === 0) {
          reportsName = this.$t(reports[report].name);
        } else {
          reportsName = reportsName + ', ' + this.$t(reports[report].name);
        }
      }
      return reportsName;
    },
    addModal() {
      this.reportsPicked = [];
      this.item = {
        Reports: '',
        Name: '',
        Email: '',
        Period: '',
        When: '',
        Active: false
      };
      this.modalType = 'add';
      this.modalOpen = true;
    },
    addRow() {
      this.invalidEmail = null;
      this.invalidReports = null;
      const reports = [];
      if (this.lang === 'he') {
        for (const report of this.reportsPicked) {
          report = this.reports[this.reportsTranslation.indexOf(report)];
        }
      }
      for (const report of this.reportsPicked) {
        reports.push({
          name: report,
          link: this.reportsLink[this.reports.indexOf(report)]
        });
      }
      const emailValidation = this.item.Email.match(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      );
      if (emailValidation === null || typeof emailValidation !== 'object') {
        this.invalidEmail = this.$t('Email format invalid');
        return;
      }
      if (reports.length === 0) {
        this.invalidReports = this.$t('You must pick reports to continue');
        return;
      }
      this.invalidEmail = null;
      this.invalidReports = null;
      if (this.modalType === 'add') {
        if (this.tableData) {
          this.tableData.push({
            Email: this.item.Email,
            Name: this.item.Name,
            Period: this.item.Period,
            When: this.item.When,
            Reports: reports,
            Active: this.item.Active ? this.item.Active : false
          });
        } else {
          this.tableData = [
            {
              Email: this.item.Email,
              Name: this.item.Name,
              Period: this.item.Period,
              When: this.item.When,
              Reports: reports,
              Active: this.item.Active ? this.item.Active : false
            }
          ];
        }
      } else {
        Vue.set(this.tableData, this.index, {
          Email: this.item.Email,
          Name: this.item.Name,
          Period: this.item.Period,
          When: this.item.When,
          Reports: reports,
          Active: this.item.Active ? this.item.Active : false
        });
      }
      this.modalOpen = false;
    },
    editModal(index) {
      const item = JSON.parse(JSON.stringify(this.tableData[index]));
      if (!item.hasOwnProperty('Email')) item.Email = '';
      if (!item.hasOwnProperty('Name')) item.Name = '';
      if (!item.hasOwnProperty('Period')) item.Period = '';
      if (!item.hasOwnProperty('When')) item.When = '';
      if (!item.hasOwnProperty('Active')) item.Active = false;
      this.item = item;
      this.index = index;
      this.reportsPicked = item.Reports.map((report) => report.name);
      this.modalType = 'edit';
      this.modalOpen = true;
    },
    deleteRow(item) {
      Vue.set(this.tableData, this.tableData.splice(item, 1));
      this.modalOpen = false;
    },
    deleteModal(index) {
      this.index = index;
      this.modalType = 'delete';
      this.modalOpen = true;
    },
    closeModal() {
      this.modalType = '';
      this.modalOpen = false;
    },
    saveChanges() {
      setProjectProperty(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        'meta.reports_contacts',
        this.tableData
      ).then((res) => {
        if (res && res.hasOwnProperty('status') && res.status === 200) {
          this.$notify({
            text: 'Successfully updated Contacts!',
            type: 'success',
            title: 'Success!'
          });
        }
      });
    }
  },
  computed: {
    language() {
      return this.$store.state.Global.lang;
    }
  },
  watch: {
    language(newVal) {
      this.lang = newVal;
      this.periodOptions = [
        { value: 'daily', label: this.$t('daily') },
        { value: 'weekly', label: this.$t('weekly') },
        { value: 'monthly', label: this.$t('monthly') },
        { value: 'yearly', label: this.$t('yearly') }
      ];
      this.timeOptions = [
        { value: 'sunday', label: this.$t('sunday') },
        { value: 'monday', label: this.$t('monday') },
        { value: 'tuesday', label: this.$t('tuesday') },
        { value: 'wednesday', label: this.$t('wednesday') },
        { value: 'thursday', label: this.$t('thursday') },
        { value: 'friday', label: this.$t('friday') },
        { value: 'saturday', label: this.$t('saturday') }
      ];
    }
  }
};
</script>
<style scoped>
.periodicReportsback {
  margin: 30px 20px 0px 20px;
}
.font-20 {
  font-size: 20px;
}
.clickable {
  cursor: pointer;
}
.addRowDiv {
  color: white;
  padding: 4px 20px;
  border-radius: 50px;
}
.editRowDiv {
  font-family: 'Open Sans', 'Arial', monospace !important;
  color: white;
  padding: 4px 20px;
  border-radius: 50px;
}
.cancelDiv {
  font-family: 'Open Sans', 'Arial', monospace !important;
  color: white;
  padding: 4px 20px;
  border-radius: 50px;
}
.saveDiv {
  font-family: 'Open Sans', 'Arial', monospace !important;

  padding: 4px 20px;
  border-radius: 55px;
  color: white;
}
.white-color {
  color: white;
}
.modal {
  height: 300px;
  background-color: white;
  padding: 30px;
}
.modal-body {
  margin: 20px 0;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
}
.tableWidth {
  width: 90%;
}
@media (max-width: 600px) {
  .modal-container {
    width: 350px;
  }
}
.closeIcon {
  position: absolute;
  top: 7px;
  right: 15px;
  color: black;
  cursor: pointer;
}
.userInputs {
  width: 300px;
  height: 24px;
  border: 1px solid grey;
  text-align: left;
  padding-left: 5px;
}
.userLabels {
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin: 0 20px;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-btn {
  font-family: 'Open Sans', 'Arial', monospace !important;
}
.borderedCard {
  outline: 1px solid #332a8f;
  border-radius: 20px;
}
</style>
<style>
.elevation-1{
  outline: 0px !important;
}
</style>